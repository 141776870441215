.App {
  background-color: black;
  color: white;
  min-height: 100vh;
  min-width: 100vw;
  display: flex;
  flex-direction: column;
}
.my-name{
  font-weight: 800;
  align-items: left;
  display: flex;
  flex-direction: column;
  margin-left: 2vw;
  
}
#top-row{
  flex-direction: row;
  margin-top: 1vh;
}
#pics-row-one{
  flex-direction: row;
  justify-content: center;
}
#pics-row-two{
  flex-direction: row;
  justify-content: center;
}
img{
  margin-left: 1vh;
  margin-right: 1vh;
  margin-top: 1vw;
  margin-bottom: 1vw;
  object-fit: cover;
}
#a1,#b1,#c1,#d1,#e1,#f1,#g1,#h1{
  align-items: center;
  display: flex;
  flex-direction: column;
}
#pics{
  max-height: 50vh;
}